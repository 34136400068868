<template>
    <transition name="fade-in" mode="out-in">
        <AppSpinner v-if="isLoading" />
    </transition>
    <main :class="`p-page layout--generic`" v-if="data">
        <AppPageHeader
            title="Insurance"
            :greyScale="true"
            headerImg="https://mm-aws-s3-media.s3.ap-southeast-2.amazonaws.com/large_ROD_4403_da5b42bd4e.jpeg"
        />

        <section
            :class="`content insurance gc--offset`"
            v-html="data"
        />
    </main>
</template>

<script setup>
    import { useQuery } from '@/composables/useQuery'


    // const sectionMap = {
    //     'general.content-generic': 'intro',
    //     'general.content-image-left': 'imgLeft',
    //     'general.content-image-right': 'imgRight',
    //     'general.full-width-image': 'fullImg',
    // }
    //eslint-disable-next-line
    // jQuery(document).ready(function ($){ $("#pdsContent").load(encodeURI("https://www.clubmarine.com.au/template/cmfdwc"));});
    const clubMarineUrl = encodeURI("https://www.clubmarine.com.au/template/cmfdwc")
    const { data, isLoading } = useQuery('insurance', clubMarineUrl)
    // if (data) {
    //     document.querySelector('marine').innerHTML = data
    // }
</script>
